define('modules/loading',[
    "helpers/kendo-min"
], function (kendo) {
    "use strict";

    var vm = kendo.observable({
        progressValue: 0,
        showProgress: true,
        progressLabel: "Loading..."
    });

    return {
        viewModel: function () {
            return vm;
        },
        updateProgress: function (percentage, progressLabel) {
            vm.set("progressValue", percentage);
            if (progressLabel) {
                vm.set("progressLabel", progressLabel);
            }
        },
        showProgress: function (show) {
            vm.set("showProgress", show);
        }
    };

});

