define('modules/link-resolver',[
    "config/config",
    "modules/user",
    "modules/link-builder"
], function (config, userFacade, builders) {
    "use strict";

    var resolveLinks = function () {
        var groupBuilder = new builders.GroupBuilder();
        groupBuilder.add("Supply Chain", resolveSupplyChainLinks());
        groupBuilder.add("Inventory Management", resolveInventoryLinks());

        return groupBuilder.build();
    };

    var resolveSupplyChainLinks = function () {
        var builder = new builders.LinkBuilder(),
            user = userFacade.getUser();

        if (user.roles.canViewDeliverySchedules) {
            builder.add("Delivery Schedules", config.url.deliverySchedules);
        }

        if (user.roles.canViewDistributions) {
            builder.add("Distributions", config.url.distributions);
        }
        if (user.roles.canViewIncidentReports) {
            builder.add("Incident Reports", config.url.incidenReports);
        }

        if (user.roles.canViewPoDocuments) {
            builder.add("PO Documents", config.url.poDocument);
        }

        return builder.build();
    };

    var resolveInventoryLinks = function () {
        var builder = new builders.LinkBuilder(),
                    user = userFacade.getUser();

        if (user.roles.canViewOrderMaintenance) {
            builder.add("Order Maintenance", config.url.orderMaintenance);
        }

        if (user.roles.canViewProductRecalls) {
            builder.add("Product Recalls", config.url.productRecalls);
        }

        return builder.build();
    };

    return {
        resolveLinks: resolveLinks
    };
});


