define('config/config',[], function () {
    "use strict";
    var configuration = {
        login: {
            login: "/login.php?ReturnTo=/apps",
            check: "https://api.owfg.com/security/has-valid-token",
            logout: "/logout.php?ReturnTo=/apps",
            user: "/user.php",
            roles: "/roles.php?app=home"
        },
        url: {
            orderMaintenance: "/apps/order-maintenance",
            productRecalls: "/apps/product-recalls",
            deliverySchedules: "/apps/delivery-schedules",
            distributions: "/apps/distributions",
            incidenReports: "/apps/incident-reports",
            poDocument: "/apps/po-documents"
        }
    };

    return configuration;
});

