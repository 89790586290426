define('views/home/home',[
    "helpers/kendo-min",
    "views/home/templates/home-templates",
    "modules/events",
    "modules/link-resolver"
], function (kendo, templates, events, links) {
    "use strict";

    var model = kendo.observable({
        groups: links.resolveLinks()
    });

    events.subscribe("user-changed", function () {
        model.set("groups", links.resolveLinks());
    });

    var view = new kendo.View(templates.home, {model: model});

    return view;

});
