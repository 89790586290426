(function(f, define){
    define('portal/portal.sso',[], f);
})(function(){
    (function(global, $, undefined){
        "use strict";

        var portal = global.portal = global.portal || {};

        var sso = global.portal.sso = (function(){

            var serviceBase = "/security/ssv3/rest",
                gatewayHost; //example: "https://api.dev.owfg.com:8443"

            var gateway = function(host ) {
                if( host ) {
                    gatewayHost = host;
                }
                return gatewayHost;
            };

            var getBaseUrl = function() {
                return gateway() + serviceBase;
            };

            var getUrl = function(query) {
                return getBaseUrl() + query;
            };

            var isGatewaySet = function() {
                return gateway() !== undefined;
            };

            var isValidToken = function(token,callback) {
                var url;
                if( !isGatewaySet() ) {
                    callback(true); //todo: gillih - throw error?
                } else if( token ) {
                    url = getUrl("?operation=isValidToken&token=" + token);
                    $.getJSON(url)
                        .done(function (result) {
                            if( result.data && result.data.isValidToken === "true" ) {
                                callback(true, "token is valid");
                            } else {
                                callback(false, "token is not valid");
                            }
                        })
                        .fail(function () {
                            callback(false, "serviceBase failed. could not validate token.");
                        });
                } else {
                    throw "isValidToken: Must provide token";
                }
            };

            return {
                gateway: gateway,
                isValidToken: isValidToken
            };

        }());

    }(this,jQuery));

    return this.portal;

}, typeof define == 'function' && define.amd ? define : function(_, f){ f(); });



