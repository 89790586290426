require.config({
    paths: {
        jquery: "../dependencies/jquery/dist/jquery.min",
        kendo: "../dependencies/kendo-ui/js",
        bootstrap: "../dependencies/bootstrap/dist/js/bootstrap.min",
        amplify: "../dependencies/amplify/lib/amplify.core.min",
        text: "../dependencies/requirejs-text/text",
        "kendo-template": "../dependencies/require-kendo-template/kendo-template",
        "later": "../dependencies/later/later.min",
        "portal": "../dependencies/ofg-portal/src",
        "cookies": "../dependencies/cookies-js/dist/cookies.min"
    },
    shim: {
        "jquery": {
            exports: "$"
        },
        "kendo/kendo.core.min": {
            deps: ["jquery"]
        },
        "kendo-template": {
            deps: ["jquery"]
        },
        bootstrap: {
            deps: ["jquery"]
        },
        "amplify": {
            deps: ["jquery"],
            exports: "amplify"
        },
        "later": {
            exports: "later"
        },
        "portal/portal.login": {
            deps: ["jquery"]
        }
    }
});

require([
    "jquery",
    "app",
    "config/config",
    "modules/events",
    "modules/user",
    "modules/loading",
    "portal/portal.login",
    "later",
    "bootstrap"
], function ($, app, config, events, user, loading) {
    "use strict";

    $.ajaxSetup({cache: false});

    //loading.updateProgress(20);
    user.checkLogin()
        .then(function () {
            loading.updateProgress(60);
        })
        .then(user.load)
        .then(function (currentUser) {
            loading.updateProgress(100);
            loading.showProgress(false);
            events.publish("user-changed", currentUser);
            app.init();
        });

});


define("main", function(){});

