define('views/layout/layout',[
    "helpers/kendo-min",
    "text!views/layout/layout.html",
    "config/config",
    "modules/events",
    "modules/user"
], function (kendo, template, config, events, user) {
    "use strict";

    var model = kendo.observable({
        user: user.getUser(),
        logoutUrl: config.login.logout,
        isValidUser: function () {
            var u = this.get("user");
            return (u && u.displayName);
        }
    });

    events.subscribe("user-changed", function (user) {
        model.set("user", user);
    });

    var layout = new kendo.Layout(template, {model: model});

    return layout;

});

