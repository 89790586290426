define('modules/events',[
    "amplify"
], function (amplify) {
    "use strict";

    var publish = function (topic, data) {
        amplify.publish(topic, data);
    };

    var subscribe = function (topic, callback) {
        amplify.subscribe(topic, callback);
    };

    var unsubscribe = function (topic, callback) {
        amplify.unsubscribe(topic, callback);
    };

    return {
        publish: publish,
        subscribe: subscribe,
        unsubscribe: unsubscribe
    };

});

