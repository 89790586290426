define('helpers/kendo-min',[
    "jquery",
    "kendo/kendo.core.min",
    "kendo/kendo.view.min",
    "kendo/kendo.router.min",
    "kendo/kendo.progressBar.min"
], function () {
    "use strict";

    return window.kendo;

});

