define('app',[
    "helpers/kendo-min",
    "modules/loading",
    "views/layout/layout",
    "views/home/home"
], function (kendo, loading, layout, home) {
    "use strict";

    //layout.render("#root");

    var router = new kendo.Router({
        init: function () {
        }
    });

    router.route("/", function () {
        layout.showIn("#content", home);
    });

    var init = function () {
        router.start();
        layout.render("#root");
    };

    kendo.bind("#loading", loading.viewModel());

    return {
        init: init
    };

})
;

