define('modules/link-builder',[], function () {
    "use strict";

    var linkBuilder = function () {
        var links = [];

        return {
            add: function (label, url) {
                links.push({label: label, url: url});
                return this;
            },
            build: function () {
                return links;
            }
        };
    };

    var groupBuilder = function () {
        var groups = [];

        return {
            add: function (name, links) {
                groups.push({name: name, links: links});
                return this;
            },
            build: function () {
                return groups;
            }
        };
    };

    return {
        GroupBuilder: groupBuilder,
        LinkBuilder: linkBuilder
    };

});

