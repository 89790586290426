(function (f, define) {
    define('portal/portal.login',["./portal.sso"], f);
})(function () {

    (function (global, $, undefined) {
        "use strict";

        var login = global.portal.login = (function () {

            var sso = global.portal.sso,
                tokenCookieName = "iPlanetDirectoryPro";

            var securityTokenCookieName = function (name) {
                if (name) {
                    tokenCookieName = name;
                }
                return tokenCookieName;
            };

            var getSecurityToken = function () {
                var name = securityTokenCookieName() + "=";
                var ca = document.cookie.split(';');
                var result;
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        result = c.substring(name.length, c.length);
                    }
                }
                return result;
            };

            var isSecurityTokenCookieSet = function () {
                return getSecurityToken() !== undefined;
            };

            var checkSession = function (callback) {
                var token = getSecurityToken();

                if( token ) {
                    sso.isValidToken(token, callback);
                } else {
                    callback(false, "cookie (" + securityTokenCookieName() + ") is not set.");
                }
            };

            return {
                securityTokenCookieName: securityTokenCookieName,
                isSecurityTokenCookieSet: isSecurityTokenCookieSet,
                getSecurityToken: getSecurityToken,
                checkSession: checkSession
            }

        }());

        return global.portal;

    }(this, jQuery));

    return this.portal;

}, typeof define == 'function' && define.amd ? define : function (_, f) {
    f();
});



