define('modules/user',[
    "jquery",
    "config/config"
], function ($, config) {
    "use strict";

    var roles = {
        canViewOrderMaintenance: false,
        canViewProductRecalls: false,
        canViewDeliverySchedules: false,
        canViewDistributions: false,
        canViewIncidentReports: false,
        canViewPoDocuments: false
    };

    var user = {
        "uid": "",
        "firstName": "",
        "lastName": "",
        "email": null,
        "defaultLocation": "",
        "locations": [],
        roles: roles
    };

    var updateDisplayName = function (user) {
        var displayName = "";
        if (user.firstName) {
            displayName = user.firstName;
            if (user.lastName) {
                displayName += " " + user.lastName;
            }
        }

        user.displayName = displayName;

    };

    var lookupUser = function () {
        return getJson(config.login.user);
    };

    var lookupRoles = function () {
        return getJson(config.login.roles);
    };

    var getJson = function (url) {
        return $.ajax({
            url: url,
            type: "GET",
            xhrFields: {
                withCredentials: true
            }
        });
    };

    var load = function () {
        var d = new $.Deferred(),
            calls = [
            lookupUser(),
            lookupRoles()
        ];

        $.when.apply($, calls).done(function (userResult, roleResult) {
            if (userResult[1] === "success") {
                user = $.extend({}, userResult[0]);
                if (roleResult[1] === "success") {
                    user = $.extend(user, roleResult[0]);
                }
                updateDisplayName(user);
                d.resolve(user);
            }
            d.reject();
        });

        return d.promise();
    };

    var checkLogin = function () {
        var d = new $.Deferred(),
            url = config.login.check,
            request;

        request = getJson(url);
        request.done(function () {
            d.resolve();
        });
        request.fail(function (jqxhr) {
            if (jqxhr.status === 401) {
                d.resolve();
                login();
            } else {
                d.reject();
            }
        });

        return d.promise();
    };

    var login = function () {
        window.location.href = config.login.login;
    };

    return {
        load: load,
        getUser: function () {
            return user;
        },
        checkLogin: checkLogin
    };
});


