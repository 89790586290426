define('views/home/templates/home-templates',[
    "kendo-template!views/home/home",
    "kendo-template!views/home/templates/app-link-group",
    "kendo-template!views/home/templates/app-link"
], function (home, appLinkGroup, appLink) {
    "use strict";

    return {
        home: home,
        appLinkGroup: appLinkGroup,
        appLink: appLink
    };

});
